<template>
	<v-row :no-gutters="$vuetify.breakpoint.smAndUp">
		<div class="font-weight-bold mb-3">{{ act.title }}</div>
		<v-card elevation="0" style="min-height: 60vh; width: 100%">
			<br /><br /><br /><br />
			<div class="pa-3 text-center">
				<p class="" v-html="act.theory.description"></p>
				<img src="@/assets/images/input-file.png" class="text-center mb-2" />
				<br />
				<v-btn
					rounded
					download
					depressed
					color="primary"
					class="mx-auto text-center"
					:href="act.theory.file.url"
					target="_blank"
					:loading="btn_loading"
					v-if="act.is_complete === true"
				>
					Download
				</v-btn>
				<v-btn
					rounded
					download
					depressed
					color="primary"
					class="mx-auto text-center"
					:href="act.theory.file.url"
					target="_blank"
					:loading="btn_loading"
					@click="getUnlock()"
					v-else
				>
					Download
				</v-btn>
			</div>
		</v-card>
	</v-row>
</template>

<script>
// import moment from "moment";

export default {
	props: {
		act: Object,
		detail_topic: Object,
		id_topic: String,
		id_act: String,
	},
	data() {
		return {
			btn_loading: false,
		}
	},
	methods: {
		async getHasDoneActivity() {
			const data = {
				member_redeem_id: this.$route.params.id,
				topic_id: this.id_topic,
				activity_id: this.id_act,
			};
			await this.axios.post(`/users/v1/member/activity/update-has-done`, data)
			.then(() => {
				this.btn_loading = false
				this.$emit('getDetailClass')
			}).catch(() => {
				this.btn_loading = false
			});
		},
		getUnlock() {
			// let next_topic_at = this.detail_topic.next_topic_at
			// let date_now = new Date()
			// let next_topic_date = moment(next_topic_at).format('YYYY-MM-DD HH:mm:ss')
			// let date_now_at = moment(date_now).format('YYYY-MM-DD HH:mm:ss')
			// let next_topic_date_res = moment(next_topic_at).format('DD MMMM YYYY HH:mm:ss')

			// // Check apakah aktivitas ini sudah selesai atau belum
			// if(!this.act.has_done_this_activity) {
			// 	this.getHasDoneActivity()
			// }
			
			// // check if next topic is under next topci at
			// if(next_topic_at != null && date_now_at < next_topic_date) {
			// 	// Chek if this activity is last in topic
      //   if(this.act.is_last_activity) {
      //     return this.notification = {
      //       state: true,
      //       message: `Mohon Maaf, Topik selanjutnya dapat di akses pada <b class="font-weight-bold">${next_topic_date_res}</b>`,
      //     };
      //   }
      // }

			this.btn_loading = true
			const data = {
				program_id: this.$route.params.id,
				topic_id: this.id_topic,
				activity_id: this.id_act,
			};
			this.axios.post(`/users/v1/member/activity/unlock`, data)
			.then(async () => {
				this.btn_loading = false
				this.$emit('getDetailClass')
			})
			.catch(() => {
				this.btn_loading = false
			});
		},
	}
}
</script>